<template>
  <v-row v-if="streaming" class="pb-6">
    <v-col class="py-0" cols="12" sm="12">
      <h3 class="field-title" v-text="$t('activity.editActivity.streaming')" />
    </v-col>
    <v-col v-if="['notStarted', 'running'].includes(streaming.status)" class="field" cols="12" sm="12">
      <v-text-field
        :value="streamingUrl"
        :label="$t('activity.editActivity.streamingUrl')"
        outlined
        dense hide-details
        readonly
      >
        <template #append>
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-icon class="ml-1" color="primary" v-on="on" @click="copyStreamingUrlToClipboard">
                content_copy
              </v-icon>
            </template>
            {{ $t('activity.editActivity.copyStreamingUrl') }}
          </v-tooltip>
        </template>
      </v-text-field>
    </v-col>
    <v-col v-if="['notStarted', 'running'].includes(streaming.status)" cols="12" sm="6">
      <v-btn
        :to="{name: 'streaming', params: { organizationId, projectId, activityId }}"
        color="primary" block depressed
        v-text="$t('activity.editActivity.openStreaming')"
      />
    </v-col>
    <v-col v-if="streaming.status === 'running'" cols="12" sm="6">
      <v-btn
        block outlined
        color="error"
        @click="finishStreaming"
        v-text="$t('streaming.finish')"
      />
    </v-col>
    <v-col v-if="streaming.status === 'finished'" class="field" cols="12" sm="6">
      <v-btn
        block depressed color="primary"
        :disabled="!streamingDownloadUrl"
        :href="streamingDownloadUrl" :download="`${$store.state.activity.dbData.name}.mp4`"
      >
        {{ $t('activity.editActivity.downloadStreaming') }}
        <v-progress-circular
          v-if="!streamingDownloadUrl"
          :size="20" :width="2"
          class="ml-3"
          indeterminate
        />
      </v-btn>
    </v-col>
    <v-dialog v-if="streaming.status === 'finished'" v-model="streamingRecordingDialog" max-width="600">
      <template #activator="{ on, attrs }">
        <v-col class="field" cols="12" sm="6">
          <v-btn
            v-bind="attrs" color="primary"
            block depressed v-on="on"
            v-text="$t('activity.editActivity.setStreamingRecording')"
          />
        </v-col>
      </template>
      <v-card class="pa-8">
        <p class="headline pb-4" v-text="$t('activity.editActivity.setStreamingRecording')" />
        <video-field v-model="streamingRecording" class="pb-4" />
        <!-- <v-text-field v-model="streamingRecording" class="pb-4" outlined dense :label="$t('activity.editActivity.videoUrl')" /> -->
        <v-btn color="primary" @click="updateStreamingRecording()" v-text="$t('common.accept')" />
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import getBasePath from '@/utils/getBasePath'
import { getStorageFileUrl } from '@/services/firebase'

export default {
  name: 'EditActivityStreaming',
  components: {
    VideoField: () => import('@/components/formFields/VideoField'),
  },
  props: {
    organizationId: { type: String, required: true },
    projectId: { type: String, required: true },
    activityId: { type: String, default: null },
  },
  data() {
    return {
      streamingRecording: null,
      streamingRecordingDialog: false,
      streamingUrl: null,
      streamingDownloadUrl: null,
      getStreamingDownloadUrlTimeout: null,
    }
  },
  computed: {
    streaming() {
      return this.$store.state.streaming.dbData
    },
  },
  created() {
    this.streamingUrl = this.getStreamingUrl()
    if (this.streaming?.status === 'finished') this.getStreamingDownloadUrl()
    this.streamingRecording = this.streaming?.recording || null
  },
  beforeDestroy() {
    clearTimeout(this.getStreamingDownloadUrlTimeout)
  },
  methods: {
    getStreamingUrl() {
      const { organizationId, projectId, activityId } = this
      const props = this.$router.resolve({ name: 'streaming', params: { organizationId, projectId, activityId } })
      return getBasePath() + props.href
    },
    copyStreamingUrlToClipboard() {
      navigator.clipboard.writeText(this.streamingUrl)
      this.$store.dispatch('alert/openAlertBox', ['alertSuccess', this.$t('common.copiedSuccess')])
    },
    finishStreaming() {
      const { organizationId, streaming } = this
      this.runAsync(async () => {
        await this.$store.dispatch('streaming/finish', { organizationId, streamingId: streaming.id })
        this.getStreamingDownloadUrl()
      })
    },
    async updateStreamingRecording() {
      const { organizationId, streamingRecording: recording } = this
      const streamingId = this.streaming.id
      this.runAsync(async () => {
        await this.$store.dispatch('streaming/updateRecording', { organizationId, streamingId, recording })
        this.streamingRecordingDialog = false
      })
    },
    async getStreamingDownloadUrl() {
      try {
        const url = await getStorageFileUrl(`/streamings/${this.streaming.id}.mp4`)
        // We download the video so it's in the same domain and browsers allow direct download
        fetch(url).then(res => res.blob()).then(blob => { this.streamingDownloadUrl = URL.createObjectURL(blob) })
      } catch (err) { if (!this.streaming.recording) this.getStreamingDownloadUrlTimeout = setTimeout(this.getStreamingDownloadUrl, 10000) }
    },
  },
}
</script>

<style lang="scss" scoped>
.field-title {
  margin-bottom: 12px;
}

.field {
  padding: 4px 12px;
}
</style>
